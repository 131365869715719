@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  @apply leading-[normal] m-0;
}
*,
::before,
::after {
  border-width: 0;
}

.projects-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
}

#mockups {
  height: 500px; /* Установите высоту, чтобы модель не скрывалась */
}


.title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.description {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #666;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 10px;
}

.gallery img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gallery img:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Media Queries for responsiveness */
@media (max-width: 1024px) {
  .gallery img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 2rem;
  }

  .description {
    font-size: 1rem;
  }

  .gallery {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 1.5rem;
  }

  .description {
    font-size: 0.875rem;
  }

  .gallery {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

.navbar {
  position: relative;
  top: 38px;
  width: 100%;
  height: 76px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #fff7f7, #d8d8d8);
}

.navbar-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, #fff7f7, #d8d8d8);
  z-index: -1;
}

.navbar-content {
  display: flex;
  flex-direction: row;
  gap: 40px;
  text-transform: uppercase;
  font-weight: 500;
  white-space: nowrap;
  font-size: 16px;
  letter-spacing: 2px;
  color: #333;
}

.navbar-content p {
  cursor: pointer;
  transition: color 0.3s ease;
}

.navbar-content p:hover {
  color: #007bff;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .navbar {
    top: 0;
    height: 60px;
  }

  .navbar-content {
    gap: 20px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .navbar {
    height: 50px;
  }

  .navbar-content {
    gap: 10px;
    font-size: 12px;
    flex-wrap: wrap;
  }
}

/* CarouselComponent.css */
.carousel-container {
  width: 250px;
  margin: 0 auto;
  padding: 20px 0;
}

.carousel-slide {
  position: relative;
  text-align: center;
  color: white;
}

.carousel-image {
  width: 100%;
  border-radius: 10px;
}

.carousel-caption {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 18px;
}

.navbar-mobile {
  background: linear-gradient(90deg, #fff7f7, #d8d8d8);
}
